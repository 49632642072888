// @flow
import * as React from 'react';
import withStyles, {
  type StyleRulesCallback,
  type WithStyles,
} from '@material-ui/core/styles/withStyles';
import type { Theme } from '@material-ui/core/styles/createMuiTheme';
import Typography from '@material-ui/core/Typography';
import Avatar from '@material-ui/core/Avatar';
import classnames from 'classnames';
import type { PrismicLinkType } from '~schema';

import BodySection from '~plugins/material-ui/components/BodySection';
import getAuthorType from '~helpers/getAuthorType';

export type ClassKey =
  | 'root'
  | 'wrapper'
  | 'content'
  | 'author'
  | 'authorPosition'
  | 'authorExtraInfo'
  | 'authorAvatar';

export type Props = {
  ...$Exact<WithStyles<ClassKey>>,
  className?: string,
  author: ?PrismicLinkType,
};

export type Styles = StyleRulesCallback<Theme, Props, ClassKey>;

export const styles: Styles = theme => ({
  root: {
    textAlign: 'center',
    '&:before': {
      content: JSON.stringify(''),
      display: 'block',
      width: '90%',
      height: 1,
      background: theme.palette.divider,
      margin: 'auto',
      [theme.breakpoints.up('md')]: {
        width: '60%',
      },
    },
  },
  wrapper: {},
  content: {},
  author: {
    color: theme.palette.text.secondary,
    textTransform: 'uppercase',
    fontFamily: theme.typography.h4.fontFamily,
  },
  authorPosition: {},
  authorExtraInfo: {
    paddingTop: theme.spacing(2),
  },
  authorAvatar: {
    width: theme.spacing(10),
    height: theme.spacing(10),
    marginBottom: theme.spacing(2),
    margin: 'auto',
  },
});

const Author = ({
  author,
  className,
  classes,
  ...props
}: Props): React.Node => {
  const authorData = getAuthorType(author);
  return authorData ? (
    <div className={classnames(classes.root, className)} {...props}>
      <BodySection
        ContainerProps={{ maxWidth: 'sm' }}
        className={classes.wrapper}
      >
        {authorData?.data?.avatar && authorData?.data?.avatar?.url ? (
          <Avatar
            alt={authorData?.data?.name?.text || ''}
            src={authorData.data?.avatar?.url || ''}
            className={classes.authorAvatar}
          />
        ) : null}
        <div className={classes.content}>
          <Typography variant="h5" className={classes.author}>
            {authorData?.data?.name?.text}
          </Typography>
          {authorData?.data?.position && authorData?.data?.position?.text ? (
            <Typography variant="overline" className={classes.authorPosition}>
              {authorData?.data?.position?.text}
            </Typography>
          ) : null}
          {authorData?.data?.extra_info &&
          authorData?.data?.extra_info?.text ? (
            <Typography className={classes.authorExtraInfo}>
              {authorData?.data?.extra_info?.text}
            </Typography>
          ) : null}
        </div>
      </BodySection>
    </div>
  ) : null;
};

Author.defaultProps = {
  className: undefined,
};

export default withStyles<*, *, Props>(styles)(Author);
