// @flow
import type { Styles } from './types';

const styles: Styles = theme => ({
  root: {
    marginTop: 51,
    [theme.breakpoints.up('lg')]: {
      marginTop: 89,
    },
  },
  wrapper: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    textAlign: 'center',
    color: theme.palette.common.black,
    position: 'relative',
    '&:after': {
      content: JSON.stringify(''),
      position: 'absolute',
      bottom: 0,
      width: '40%',
      height: 1,
      transform: 'translateX(70%)',
      background: theme.palette.divider,
      margin: 'auto',
    },
  },
  title: {
    ...theme.typography.h3,
    textTransform: 'uppercase',
  },
  subtitle: {
    maxWidth: theme.breakpoints.values.sm,
    color: theme.palette.text.primary,
    marginTop: theme.spacing(1),
    margin: 'auto',
    ...theme.typography.caption,
  },
  backgroundImage: ({ backgroundImage }) => ({
    width: '100%',
    height: '60vh',
    ...(backgroundImage
      ? {
          backgroundImage: `url(${backgroundImage})`,
          backgroundBlendMode: 'multiply',
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center center',
        }
      : null),
  }),
  videoWrapper: {
    position: 'relative',
    paddingTop: '56.25%',
    height: 'auto',
  },
  video: {
    position: 'absolute',
    top: 0,
    left: 0,
  },
});

export default styles;
