// @flow
import * as React from 'react';
import { useIntl } from 'react-intl';
import withStyles from '@material-ui/core/styles/withStyles';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import classnames from 'classnames';

import ResPadding from '~plugins/material-ui/components/ResPadding';
import { hasRichTextFieldValue } from '~plugins/prismic/components/RichText';
import RichTextTypography from '~plugins/prismic/components/RichTextTypography';
import VideoPlayer from '~components/VideoPlayer';

import type { Props } from './types';
import styles from './styles';

const StoryHeader = ({
  title,
  subtitle,
  backgroundImage,
  backgroundVideo,
  date,
  className,
  classes,
  ...props
}: Props): React.Node => {
  const intl = useIntl();
  const dateOptions = { day: 'numeric', month: 'long', year: 'numeric' };

  return (
    <div className={classnames(classes.root, className)} {...props}>
      {backgroundVideo ? (
        <div className={classes.videoWrapper}>
          <VideoPlayer
            controls
            playsinline
            width="100%"
            height="100%"
            url={backgroundVideo}
            videoThumbnail={backgroundImage}
            classes={{
              root: classes.video,
            }}
          />
        </div>
      ) : (
        <div className={classes.backgroundImage} />
      )}
      <Container
        maxWidth="md"
        component={ResPadding}
        vertical
        className={classes.wrapper}
      >
        {date ? (
          <Typography variant="overline" gutterBottom>
            {intl.formatDate(date, dateOptions)}
          </Typography>
        ) : null}
        <Typography variant="h1" className={classes.title}>
          {title}
        </Typography>
        {hasRichTextFieldValue(subtitle) ? (
          <RichTextTypography
            component="h2"
            {...subtitle}
            className={classes.subtitle}
          />
        ) : null}
      </Container>
    </div>
  );
};

StoryHeader.defaultProps = {
  className: undefined,
};

export default withStyles<*, *, Props>(styles)(StoryHeader);
